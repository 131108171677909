import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/authContext";
import PageLayout from "../../components/PageLayout";
import PageContent from "../../components/PageContent";
import PageBoundary from "../../components/PageBoundary";
import ReportFilter from "../../components/ReportFilter";
import ReportList from "../../components/ReportList";
import { useDocuments } from "../../hooks/documentsHook";
import useFetchAllTickers from "../../hooks/tickersHook";
import LoadingStatus from "../../components/LoadingStatus";
import BannerComponent from "../../components/Banner";

const InsightsPage: React.FC = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const {
    tickers: tickersData,
    isLoading: isLoadingTickers,
    error: errorLoadingTickers,
  } = useFetchAllTickers(accessToken);

  const tickers = tickersData?.map((ticker) => ticker.attributes) || [];

  const {
    isLoadingInitial,
    error: errorLoadingDocuments,
    documents,
    selectedTickers,
    setSelectedTickersWithReset,
    selectedPeriodFilter,
    setSelectedPeriodFilterWithReset,
    selectedSort,
    setSelectedSortWithReset,
    hasMore,
    loadMoreDocuments,
    isLoadingMore,
  } = useDocuments(accessToken);

  useEffect(() => {
    let ignore = true;
    if (errorLoadingDocuments?.status === 401 && !ignore && clearIdentity) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [errorLoadingDocuments?.status]);

  let reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  if (isLoadingInitial) {
    reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  } else if (errorLoadingDocuments) {
    reportListTemplate = <LoadingStatus loadingType="reports_error" />;
  } else if (documents.length === 0) {
    reportListTemplate = <LoadingStatus loadingType="reports_empty" />;
  } else {
    reportListTemplate = (
      <ReportList
        reports={documents}
        hasMore={hasMore}
        loadMore={loadMoreDocuments}
        isLoadingMore={isLoadingMore}
      />
    );
  }

  const tempSEOData = {
    title: "BAM Applied Insights",
    description: "Balyasny's AI-Enabled Research Platform for Core Investors",
  };

  const tempBannerData = {
    title1: "Access AI-Enabled research from BAM's proprietary catalogue",
    description: `Welcome to Balyasny's new AI-enabled research platform created exclusively for our Core Investors. This tool generates robust summaries from our research library. BAM is breaking ground as the first multi-strategy firm to offer its investors such a platform, representing our commitment to transparency and partnership.`,
  };

  return (
    <PageLayout seo={tempSEOData}>
      <PageContent className="flex flex-col">
        {errorLoadingTickers ? (
          <LoadingStatus loadingType="page_error" />
        ) : (
          <div className="flex flex-col">
            <BannerComponent
              title1={tempBannerData.title1}
              description={tempBannerData.description}
            />
            <PageBoundary className="w-full">
              <ReportFilter
                tickers={tickers}
                selectedTickers={selectedTickers}
                setSelectedTickers={setSelectedTickersWithReset}
                selectedPeriodFilter={selectedPeriodFilter}
                setSelectedPeriodFilter={setSelectedPeriodFilterWithReset}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSortWithReset}
                loadingTickers={isLoadingTickers}
              />
              {reportListTemplate}
            </PageBoundary>
          </div>
        )}
      </PageContent>
    </PageLayout>
  );
};

export default InsightsPage;
