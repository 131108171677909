import { useState, useEffect } from "react";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  TickerAttributes,
} from "../types/cms";
import { AxiosResponse } from "axios";
import { fetchDataFromCMS } from "../cms-api";

const PAGE_SIZE = 100;

const useFetchAllTickers = (accessToken: string | null) => {
  const [tickers, setTickers] = useState<CMSAPIDataItem<TickerAttributes>[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<AxiosResponse<any, any> | null>(null);

  const fetchTickers = async (page: number) => {
    const queryParams = {
      sort: ["ticker:asc"],
      fields: ["ticker", "company_name", "logo_url", "featured"],
      pagination: {
        pageSize: PAGE_SIZE,
        page: page,
      },
    };

    try {
      const { data, error } = await fetchDataFromCMS<
        CMSAPIDataCollection<CMSAPIDataItem<TickerAttributes>>
      >("/api/tickers", accessToken, queryParams, {
        method: "get",
      });

      if (error) {
        throw error;
      }

      if (data) {
        setTickers((prev) => [...prev, ...data.data]);
        if (page < data.meta.pagination.pageCount) {
          await fetchTickers(page + 1);
        } else {
          setIsLoading(false);
        }
      }
    } catch (fetchError: any) {
      setError(fetchError);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      setIsLoading(true);
      setTickers([]);
      setError(null);
      fetchTickers(1); // Start fetching from the first page
    }
  }, [accessToken]);

  return { tickers, isLoading, error };
};

export default useFetchAllTickers;
